@import "../../variables.scss";

.MainPage {
    @include main-small;
    margin-top: 15vh;

    @media only screen and (max-width: 452px) {
        margin-top: 10vh;
    }

    &__title {
        font-family: $secondary-font;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 4rem;
        width: 70%;
        line-height: 4rem;

        @media only screen and (max-width: 1380px) {
            width: 90%;
        }

        @media only screen and (max-width: 1000px) {
            width: 80%;
            font-size: 3.5rem;
            letter-spacing: 2.5px;
            line-height: 3.5rem;
        }

        @media only screen and (max-width: 568px) {
            width: 100%;
            font-size: 3rem;
            letter-spacing: 2.2px;
            line-height: 3.2rem;
        }

        @media only screen and (max-width: 452px) {
            font-size: 2.5rem;
            letter-spacing: 2px;
            line-height: 2.5rem;
        }
    }

    &__skills {
        margin-top: 10vh;
        font-weight: 300;

        @media only screen and (max-width: 1000px) {
            margin-top: 5vh;
        }

        &__span {
            margin-right: 1rem;
            font-size: 2rem;

            @media only screen and (max-width: 1000px) {
                font-size: 1.5rem;
            }

            @media only screen and (max-width: 380px) {
                display: block;
            }

        }
    }
}