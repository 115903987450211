@import "../../variables.scss";

.ContactPage {
    @include default-page;
    @include main-small;

    &__email {

        @media only screen and (max-width: 600px) {
            margin-top: 10vh;
        }

        @media only screen and (max-width: 350px) {
            font-size: 1.2rem;
        }

        text-align: center;
        font-size: 2rem;
        margin-top: 20vh;
        font-weight: 300;

        &__icon {
            width: 45px;
            height: 45px;
            margin-right: 1.5rem;
            vertical-align: bottom;

            @media only screen and (max-width: 350px) {
                width: 25px;
                height: 25px;
                margin-right: 1.5rem;
            }

        }

        a {
            text-decoration: none;
        }
    }
}