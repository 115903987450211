@import "../../_variables.scss";

.tiles {
    height: 100%;
    position: fixed;
    width: 10vw;
    padding-top: 5vh;
    right: 0;
    top: 0;
    background: -webkit-linear-gradient(right, #d1d1d1 50%, #c9c9c9 50%);
    background: -o-linear-gradient(right, #d1d1d1 50%, #c9c9c9 50%);
    background: -moz-linear-gradient(right, #d1d1d1 50%, #c9c9c9 50%);
    background: linear-gradient(to right, #f9f8eb 50%, #76b39d 50%);
    font-size: .75rem;

    @media only screen and (max-width: 1000px) {
        width: 95vw;
        position: fixed;
        right: initial;
        height: initial;
        padding: 2vh 2.5vw;
        top: initial;
        bottom: 0;
    }

    &__top {
        vertical-align: top;
        text-align: center;

        @media only screen and (max-width: 1000px) {
            text-align: left;
            vertical-align: initial;
            display: inline-block;
            width: 50%;
        }

        &__image {
            width: 16px;
            height: 16px;
        }
    }

    &__bottom {
        bottom: 5vh;
        position: absolute;

        @media only screen and (max-width: 1000px) {
            position: relative;
            bottom: initial;
            text-align: right;
            display: inline-block;
            width: 50%;
        }

    }
}

