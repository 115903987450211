@import '../../variables.scss';

.ProjectsPage {
    @include default-page;
    @include main-small;

    &__info {
        padding-bottom: 5vh;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px;
        border-style: dotted;
        width: 100%;

        @media only screen and (max-width: 1000px) {
            width: 95%;
        }
    }

    &__block {
        width: 100%;
        padding: 5vh 0;

        @media only screen and (max-width: 1000px) {
            padding: 1vh;
            width: 95%;
        }

        &__left {
            display: inline-block;
            width: 20%;
            font-size: .75rem;

            @media only screen and (max-width: 1000px) {
                display: block;
                width: 100%;
            }

            &__title {
                font-family: $secondary-font;
                text-transform: uppercase;
                letter-spacing: 3px;
                font-size: 2rem;
                line-height: 2rem;
                margin-bottom: .5rem;
            }

        }

        &__right {
            display: inline-block;
            width: 80%;
            padding-top: 1vh;

            @media only screen and (max-width: 1000px) {
                width: 95%;
                display: block;
            }
        }
    }
}