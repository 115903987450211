@import "./variables.scss";
@import url('https://fonts.googleapis.com/css?family=Anton|Open+Sans:300,400&display=swap');

html, body {
    min-height: 100% !important;
    height: 100%;
}

html, * {
    margin: 0;
    padding: 0;
    font-family: $main-font;
}

a {
    color: inherit;
}

#root {
    height: 100%;
}

a.active {
    font-style: italic;
}

.main {
    margin-top: 5vh;
    display: inline-block;
    padding-bottom: 10vh;

    @media only screen and (max-width: 1000px) {
        margin-top: 4vh;
    }

}

p {
    margin-top: 4vh;
}