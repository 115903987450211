$main-font: "Open Sans", Arial, sans-serif;
$secondary-font: "Anton", $main-font;

@mixin default-page {
    margin-top: 10vh;
    font-size: 1.2rem;

    @media only screen and (max-width: 1000px) {
      margin-top: 5vh;
      font-size: 1.1rem;
    }
}

@mixin main-small {

  @media only screen and (max-width: 1000px) {
    width: 90vw;
  }

  width: 65vw;
  margin-left: 5vw;
}