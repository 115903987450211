@import '../../variables.scss';

.Header {
    font-size: .95rem;
    @include main-small;

    &__left {
        display: inline-block;
        width: 60%;

        @media only screen and (max-width: 1000px) {
            width: 100%;
            display: block;
        }

        &__item {
            margin-right: 5vh;
            text-decoration: none;

            @media only screen and (max-width: 370px) {
                margin-right: 3.5vh;
            }
        }
    }

    &__right {
        display: inline-block;
        font-family: $secondary-font;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 3rem;
        width: 40%;
        position: absolute;
        right: 3vw;
        top: 1vh;

        @media only screen and (max-width: 1000px) {
            width: 100%;
            display: block;
            position: relative;
            top: initial;
            right: initial;
            margin-bottom: 3vh;
            line-height: 50px;
        }
    }
}