@import url(https://fonts.googleapis.com/css?family=Anton|Open+Sans:300,400&display=swap);
html,body{min-height:100% !important;height:100%}html,*{margin:0;padding:0;font-family:"Open Sans",Arial,sans-serif}a{color:inherit}#root{height:100%}a.active{font-style:italic}.main{margin-top:5vh;display:inline-block;padding-bottom:10vh}@media only screen and (max-width: 1000px){.main{margin-top:4vh}}p{margin-top:4vh}

.MainPage{width:65vw;margin-left:5vw;margin-top:15vh}@media only screen and (max-width: 1000px){.MainPage{width:90vw}}@media only screen and (max-width: 452px){.MainPage{margin-top:10vh}}.MainPage__title{font-family:"Anton","Open Sans",Arial,sans-serif;text-transform:uppercase;letter-spacing:3px;font-size:4rem;width:70%;line-height:4rem}@media only screen and (max-width: 1380px){.MainPage__title{width:90%}}@media only screen and (max-width: 1000px){.MainPage__title{width:80%;font-size:3.5rem;letter-spacing:2.5px;line-height:3.5rem}}@media only screen and (max-width: 568px){.MainPage__title{width:100%;font-size:3rem;letter-spacing:2.2px;line-height:3.2rem}}@media only screen and (max-width: 452px){.MainPage__title{font-size:2.5rem;letter-spacing:2px;line-height:2.5rem}}.MainPage__skills{margin-top:10vh;font-weight:300}@media only screen and (max-width: 1000px){.MainPage__skills{margin-top:5vh}}.MainPage__skills__span{margin-right:1rem;font-size:2rem}@media only screen and (max-width: 1000px){.MainPage__skills__span{font-size:1.5rem}}@media only screen and (max-width: 380px){.MainPage__skills__span{display:block}}

.ProjectsPage{margin-top:10vh;font-size:1.2rem;width:65vw;margin-left:5vw}@media only screen and (max-width: 1000px){.ProjectsPage{margin-top:5vh;font-size:1.1rem}}@media only screen and (max-width: 1000px){.ProjectsPage{width:90vw}}.ProjectsPage__info{padding-bottom:5vh;border-top:0;border-left:0;border-right:0;border-bottom:2px;border-style:dotted;width:100%}@media only screen and (max-width: 1000px){.ProjectsPage__info{width:95%}}.ProjectsPage__block{width:100%;padding:5vh 0}@media only screen and (max-width: 1000px){.ProjectsPage__block{padding:1vh;width:95%}}.ProjectsPage__block__left{display:inline-block;width:20%;font-size:.75rem}@media only screen and (max-width: 1000px){.ProjectsPage__block__left{display:block;width:100%}}.ProjectsPage__block__left__title{font-family:"Anton","Open Sans",Arial,sans-serif;text-transform:uppercase;letter-spacing:3px;font-size:2rem;line-height:2rem;margin-bottom:.5rem}.ProjectsPage__block__right{display:inline-block;width:80%;padding-top:1vh}@media only screen and (max-width: 1000px){.ProjectsPage__block__right{width:95%;display:block}}

.Header{font-size:.95rem;width:65vw;margin-left:5vw}@media only screen and (max-width: 1000px){.Header{width:90vw}}.Header__left{display:inline-block;width:60%}@media only screen and (max-width: 1000px){.Header__left{width:100%;display:block}}.Header__left__item{margin-right:5vh;text-decoration:none}@media only screen and (max-width: 370px){.Header__left__item{margin-right:3.5vh}}.Header__right{display:inline-block;font-family:"Anton","Open Sans",Arial,sans-serif;text-transform:uppercase;letter-spacing:1px;font-size:3rem;width:40%;position:absolute;right:3vw;top:1vh}@media only screen and (max-width: 1000px){.Header__right{width:100%;display:block;position:relative;top:auto;top:initial;right:auto;right:initial;margin-bottom:3vh;line-height:50px}}

.tiles{height:100%;position:fixed;width:10vw;padding-top:5vh;right:0;top:0;background:linear-gradient(to right, #f9f8eb 50%, #76b39d 50%);font-size:.75rem}@media only screen and (max-width: 1000px){.tiles{width:95vw;position:fixed;right:auto;right:initial;height:auto;height:initial;padding:2vh 2.5vw;top:auto;top:initial;bottom:0}}.tiles__top{vertical-align:top;text-align:center}@media only screen and (max-width: 1000px){.tiles__top{text-align:left;vertical-align:baseline;vertical-align:initial;display:inline-block;width:50%}}.tiles__top__image{width:16px;height:16px}.tiles__bottom{bottom:5vh;position:absolute}@media only screen and (max-width: 1000px){.tiles__bottom{position:relative;bottom:auto;bottom:initial;text-align:right;display:inline-block;width:50%}}

.AboutPage{margin-top:10vh;font-size:1.2rem;width:65vw;margin-left:5vw}@media only screen and (max-width: 1000px){.AboutPage{margin-top:5vh;font-size:1.1rem}}@media only screen and (max-width: 1000px){.AboutPage{width:90vw}}

.ContactPage{margin-top:10vh;font-size:1.2rem;width:65vw;margin-left:5vw}@media only screen and (max-width: 1000px){.ContactPage{margin-top:5vh;font-size:1.1rem}}@media only screen and (max-width: 1000px){.ContactPage{width:90vw}}.ContactPage__email{text-align:center;font-size:2rem;margin-top:20vh;font-weight:300}@media only screen and (max-width: 600px){.ContactPage__email{margin-top:10vh}}@media only screen and (max-width: 350px){.ContactPage__email{font-size:1.2rem}}.ContactPage__email__icon{width:45px;height:45px;margin-right:1.5rem;vertical-align:bottom}@media only screen and (max-width: 350px){.ContactPage__email__icon{width:25px;height:25px;margin-right:1.5rem}}.ContactPage__email a{text-decoration:none}

